import { SelectableButtonGroup, useTranslation } from "@lumar/shared";
import { useParams } from "react-router";
import { Severity, useRequiresManualApprovalCountQuery } from "../../graphql";
import { getFilterFromNotificationStatusFilter } from "../utils/getFilterFromNotificationStatusFilter";
import { NotificationStatusFilter } from "./constants";

export type SuggestedThresholdFilter = "all" | "requiresApproval";

interface Props {
  value: SuggestedThresholdFilter;
  onChange: (value: SuggestedThresholdFilter) => void;
  projectIds: string[];
  reportTemplateCodes: string[];
  severity: Severity | null;
  status: NotificationStatusFilter;
  crawlIds: number[];
}

export const SuggestedThresholdTabs = ({
  value,
  onChange,
  projectIds,
  reportTemplateCodes,
  severity,
  status,
  crawlIds,
}: Props): JSX.Element => {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("notifications");
  const { data } = useRequiresManualApprovalCountQuery({
    variables: {
      accountId,
      projectIds: projectIds.length ? projectIds : undefined,
      reportTemplateCodes: reportTemplateCodes.length
        ? reportTemplateCodes
        : undefined,
      severity: severity ?? undefined,
      statusFilter: getFilterFromNotificationStatusFilter(status),
      crawlIdFilter: { in: crawlIds },
    },
  });

  return (
    <SelectableButtonGroup
      size="medium"
      value={value}
      onValueChange={onChange}
      data-pendo="suggested-threshold-tabs"
      aria-label={t("showNotifications")}
      options={[
        {
          label: "All",
          value: "all",
          "data-pendo": "suggested-threshold-all-tab",
        },
        {
          label: `Requires manual approval (${
            crawlIds.length
              ? (data?.getAccount?.notifications.totalCount ?? 0)
              : 0
          })`,
          value: "requiresApproval",
          "data-pendo": "suggested-threshold-requires-approval-tab",
        },
      ]}
    />
  );
};
