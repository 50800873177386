import {
  makeStyles,
  PopperProps,
  TextField,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, AutocompleteClassKey, Skeleton } from "@material-ui/lab";
import {
  Button,
  categoryIconConfig,
  Checkbox,
  ChevronDownSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { differenceBy } from "lodash";
import { AutocompleteOptionWithTooltip } from "../AutocompleteOptionWithTooltip";
import clsx from "clsx";
import { omit } from "lodash";
import { ConditionallTooltip } from "../ConditionalTooltip";
import { ReportCategoryOption } from "../../utils/constants";
import { ClassNameMap } from "@material-ui/styles";
import { LeftBottomPopper } from "../CustomPopper/LeftBottomPopper";
import { DefaultCategoryIcon } from "./components/DefaultCategoryIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:not(.Mui-focused) .MuiAutocomplete-inputRoot": {
      "&:hover fieldset": {
        borderColor: `${theme.palette.ultraviolet[400]} !important`,
      },
      backgroundColor: theme.palette.ultraviolet[100],
      color: theme.palette.grey[700],
      fontWeight: 500,
      "& fieldset": {
        borderColor: theme.palette.ultraviolet[400],
      },
    },
  },
  autocompleteOption: {
    padding: 0,
    "&[aria-disabled='true']": {
      pointerEvents: "all!important",
      cursor: "default",
    },
    "&[aria-selected='true']": {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.ultraviolet[200],
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  list: { marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },
  option: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  input: {
    color: theme.palette.grey[700],
    "& input::placeholder": {
      color: theme.palette.grey[700],
      opacity: 1,
      textOverflow: "ellipsis",
    },
  },
  tooltip: {
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(13),
  },
  disabled: {
    pointerEvents: "none",
    cursor: "pointer",
  },
  group: {
    position: "sticky",
    top: -theme.spacing(1.2),
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    height: 40,
    zIndex: theme.zIndex.modal + 1,
  },
  paper: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grey[300],
    width: "calc(100% - 24px)",
    margin: theme.spacing(0.75, 0, 0.75, 1.5),
  },
  icon: {
    color: theme.palette.purple[400],
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2),
  },
  inputRoot: {
    flexWrap: "nowrap",
  },
  selectButton: {
    color: theme.palette.grey[900],
    boxShadow: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export type Value<Multiple, DisableClearable> = Multiple extends
  | undefined
  | false
  ? DisableClearable extends true
    ? ReportCategoryOption
    : ReportCategoryOption | null
  : Array<ReportCategoryOption>;

interface Props<Multiple, DisableClearable> {
  id?: string;
  value: Value<Multiple, DisableClearable> | undefined;
  options: ReportCategoryOption[];
  multiple?: Multiple;
  onChange: (newReports: Value<Multiple, DisableClearable>) => void;
  onBlur?: () => void;
  disabled?: boolean;
  maxSelection?: number;
  className?: string;
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>;
  disableClearable?: DisableClearable;
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  PopperComponent?: React.ComponentType<PopperProps>;
  "data-pendo"?: string;
  "data-testid"?: string;
  loading?: boolean;
  maxWidth?: number;
  variant?: "autocomplete" | "select";
  tooltip?: string;
  open?: boolean;
  selectOnFocus?: boolean;
  fullWidth?: boolean;
}

export function ReportCategoriesCombobox<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>({
  value,
  disabled,
  multiple,
  maxSelection,
  onChange,
  onBlur,
  className,
  classes: outsideClasses,
  disableClearable,
  options,
  maxWidth,
  variant = "autocomplete",
  tooltip,
  open = false,
  ...props
}: Props<Multiple, DisableClearable>): JSX.Element {
  const [isOpen, setIsOpen] = useState(open);
  const [scrollTo, setScrollTo] = useState<string | undefined>();

  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation(["components"]);

  const [inputValue, setInputValue] = useState("");

  function sortOptions(
    a: ReportCategoryOption,
    b: ReportCategoryOption,
  ): number {
    const isASelected = Boolean(
      (value as ReportCategoryOption[]).find((o) => o.code === a.code),
    );
    const isBSelected = Boolean(
      (value as ReportCategoryOption[]).find((o) => o.code === b.code),
    );
    if ((isASelected && isBSelected) || (!isASelected && !isBSelected))
      return 0;
    return isASelected ? -1 : 1;
  }

  // eslint-disable-next-line fp/no-mutating-methods
  const currentList = multiple ? [...options].sort(sortOptions) : options;

  useEffect(() => {
    if (scrollTo && multiple) {
      const element = document.getElementById(`report-${scrollTo}`);
      element?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    }
  }, [scrollTo, multiple]);

  const hasReachedMaxNumberOfProjects = Array.isArray(value)
    ? value.length >= (maxSelection ?? 1000000000)
    : false;

  function isSelected(option: ReportCategoryOption): boolean {
    if (!value) return false;
    if (Array.isArray(value))
      return Boolean(value.find((e) => e.code === option.code));
    return value.code === option.code;
  }

  const selected: Value<Multiple, DisableClearable> | undefined =
    useMemo(() => {
      return Array.isArray(value)
        ? value
        : ((currentList.find((e) => e.code === value?.code) as Value<
            Multiple,
            DisableClearable
          >) ?? value);
    }, [value, currentList]);

  function getPlaceholder(): string {
    if (multiple) {
      if (props.loading) return "";
      return isOpen
        ? t("typeto")
        : !(selected as ReportCategoryOption[]).length
          ? t("allreports")
          : "";
    }

    return isOpen
      ? t("typeto")
      : ((selected as ReportCategoryOption)?.name ?? "");
  }

  if (props.loading) {
    return (
      <Skeleton
        variant="rect"
        width={300}
        height={41}
        style={{ borderRadius: 8 }}
      />
    );
  }

  return (
    <Autocomplete
      {...props}
      className={className}
      disabled={disabled}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={(e, r) => {
        if (isOpen && r === "toggleInput" && e.type === "mousedown") return;
        setIsOpen(false);
        setInputValue("");
      }}
      disableCloseOnSelect={multiple}
      disableClearable={disableClearable || isOpen}
      multiple={multiple}
      value={selected}
      onChange={(_, selection) => {
        if (Array.isArray(selection) && multiple) {
          const newElement = differenceBy(
            selection,
            value as ReportCategoryOption[],
            "code",
          );
          if (newElement?.length) setScrollTo(newElement[0].id);
          onChange(selection as Value<Multiple, DisableClearable>);
        } else {
          onChange(selection as Value<Multiple, DisableClearable>);
          setIsOpen(false);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (!event) return;
        if (event.type === "change") setInputValue(newInputValue);
      }}
      classes={{
        ...omit(outsideClasses, "root", "option", "listbox", "paper"),
        root: clsx(
          outsideClasses?.root,
          Array.isArray(value) && value.length ? classes.root : undefined,
        ),
        option: clsx(outsideClasses?.option, classes.autocompleteOption),
        listbox: clsx(outsideClasses?.listbox, classes.list),
        paper: clsx(outsideClasses?.paper, classes.paper),
        inputRoot: classes.inputRoot,
      }}
      data-pendo={`notifications-page-reports-filter-select`}
      data-testid="notifications-page-reports-combobox"
      onBlur={() => {
        if (inputValue !== "") setInputValue("");
        onBlur?.();
      }}
      options={currentList}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => {
        const hasReachedMaxNumberOfReports = Array.isArray(value)
          ? value.length === maxSelection
          : false;
        if (multiple && Array.isArray(value)) {
          const isSelected = value.some((v) => v.code === option.code);
          return hasReachedMaxNumberOfReports && !isSelected;
        }
        return false;
      }}
      renderOption={(option, state) => {
        const disabled = hasReachedMaxNumberOfProjects && !state.selected;
        const Icon = categoryIconConfig.get(option.code) ?? DefaultCategoryIcon;
        return (
          <ConditionallTooltip
            show={disabled}
            classes={{ tooltip: classes.tooltip }}
            arrow={false}
            title={
              t("maxselectReportTooltip", {
                count: maxSelection,
              }) as string
            }
          >
            <div
              id={`report-${option.id}`}
              style={{
                display: "flex",
                cursor: disabled ? "default" : "pointer",
                width: "100%",
                flex: 1,
                paddingTop: 2,
                paddingBottom: 1,
              }}
              onClick={(e) => {
                if (disabled) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: theme.spacing(
                    0.75,
                    1,
                    0.75,
                    1 + (option.level ?? 0),
                  ),
                  width: "100%",
                }}
              >
                {multiple ? (
                  <Checkbox size="medium" checked={state.selected} />
                ) : undefined}
                <Icon
                  style={{
                    color: theme.palette.ultraviolet[400],
                    width: 17,
                    height: 17,
                  }}
                />
                <AutocompleteOptionWithTooltip
                  title={option.name}
                  description={option.description}
                  state={{
                    inputValue: state.inputValue,
                    selected: multiple ? false : isSelected(option),
                  }}
                  className={classes.option}
                />
              </div>
            </div>
          </ConditionallTooltip>
        );
      }}
      getOptionSelected={(option, value) => {
        return option.code === value.code;
      }}
      fullWidth
      style={{ maxWidth: maxWidth ?? 300 }}
      openOnFocus
      PopperComponent={LeftBottomPopper}
      renderInput={(params) => {
        if (variant === "select" && !isOpen)
          return (
            <Tooltip title={tooltip ?? ""} arrow={false}>
              <span>
                <Button
                  style={{ paddingRight: 11, width: "100%" }}
                  variant="text"
                  color="primary"
                  disabled={disabled}
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(true);
                  }}
                  className={classes.selectButton}
                >
                  <Typography
                    variant="h6SemiBold"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: 20,
                      fontSize: theme.typography.pxToRem(18),
                      marginTop: 2,
                    }}
                  >
                    {getPlaceholder()}
                  </Typography>
                  <div style={{ flex: 1 }} />
                  <ChevronDownSolid style={{ width: 20, height: 20 }} />
                </Button>
              </span>
            </Tooltip>
          );
        return (
          <TextField
            {...params}
            variant="outlined"
            focused={false}
            InputProps={{
              ...params.InputProps,
              autoFocus: variant === "select",
              className: clsx(classes.input, params.InputProps.className),
            }}
            placeholder={getPlaceholder()}
          />
        );
      }}
      renderTags={(selectedReports) => {
        return !isOpen ? (
          <span>{t("reportsselected", { count: selectedReports.length })}</span>
        ) : null;
      }}
    />
  );
}
