import {
  makeStyles,
  PopperProps,
  TextField,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { FocusEventHandler, useEffect, useMemo, useState } from "react";
import { useReportTemplateAccumulator } from "../../hooks/useReportTemplateAccumulator";
import { Autocomplete, AutocompleteClassKey, Skeleton } from "@material-ui/lab";
import {
  Button,
  ChevronDownSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { ReportTemplate } from "../../../graphql";
import { differenceBy } from "lodash";
import clsx from "clsx";
import { omit } from "lodash";
import { ClassNameMap } from "@material-ui/styles";
import { LeftBottomPopper } from "../CustomPopper/LeftBottomPopper";
import { useComboboxTextWidth } from "../utils/useComboboxTextWidth";
import { ReportOptionElement } from "./components/ReportOptionElement";
import { ReportOptionGroupElement } from "./components/ReportOptionGroupElement";
import { ReportOption } from "../../utils/constants";

const COMBOBOX_WIDTH = 253;

export type Value<Multiple, DisableClearable> = Multiple extends
  | undefined
  | false
  ? DisableClearable extends true
    ? ReportOption
    : ReportOption | null
  : Array<ReportOption>;

interface Props<Multiple, DisableClearable> {
  value: Value<Multiple, DisableClearable>;
  options?: ReportOption[];
  multiple?: Multiple;
  onChange: (newReports: Value<Multiple, DisableClearable>) => void;
  disabled?: boolean;
  maxSelection?: number;
  className?: string;
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>;
  disableClearable?: DisableClearable;
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  PopperComponent?: React.ComponentType<PopperProps>;
  "data-pendo"?: string;
  "data-testid"?: string;
  loading?: boolean;
  maxWidth?: number;
  onBlur?: FocusEventHandler<
    HTMLDivElement | HTMLInputElement | HTMLTextAreaElement
  >;
  variant?: "autocomplete" | "select";
  placeholder?: string;
  projectId?: string;
}

export function ReportsCombobox<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>({
  value,
  disabled = true,
  multiple,
  maxSelection,
  onChange,
  className,
  classes: outsideClasses,
  disableClearable,
  options,
  maxWidth,
  onBlur,
  variant = "autocomplete",
  placeholder = "",
  projectId,
  ...props
}: Props<Multiple, DisableClearable>): JSX.Element {
  const {
    reports: reportOptions,
    categories,
    loading: loadingTemplates,
  } = useReportTemplateAccumulator({ options, projectId });

  const comboboxTextWidth = useComboboxTextWidth(props.loading);

  const [isOpen, setIsOpen] = useState(false);
  const [scrollTo, setScrollTo] = useState<string | undefined>();

  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation(["notifications"]);

  const [inputValue, setInputValue] = useState("");

  function sortOptions(
    a: Pick<
      ReportTemplate,
      "id" | "code" | "name" | "description" | "totalSign"
    >,
    b: Pick<
      ReportTemplate,
      "id" | "code" | "name" | "description" | "totalSign"
    >,
  ): number {
    const isASelected = Boolean(
      (value as ReportOption[]).find((o) => o.code === a.code),
    );
    const isBSelected = Boolean(
      (value as ReportOption[]).find((o) => o.code === b.code),
    );
    if ((isASelected && isBSelected) || (!isASelected && !isBSelected))
      return 0;
    return isASelected ? -1 : 1;
  }

  const currentList = multiple
    ? // eslint-disable-next-line fp/no-mutating-methods
      [...(reportOptions ?? [])].sort(sortOptions)
    : reportOptions;

  useEffect(() => {
    if (scrollTo && multiple) {
      const element = document.getElementById(`report-${scrollTo}`);
      element?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    }
  }, [scrollTo, multiple]);

  const hasReachedMaxNumberOfProjects = Array.isArray(value)
    ? value.length >= (maxSelection ?? 1000000000)
    : false;

  function isSelected(option: ReportOption): boolean {
    if (!value) return false;
    if (Array.isArray(value))
      return Boolean(value.find((e) => e.code === option.code));
    return value.code === option.code;
  }

  const selected: Value<Multiple, DisableClearable> = useMemo(() => {
    return Array.isArray(value)
      ? value
      : ((reportOptions?.find((e) => e.code === value?.code) as Value<
          Multiple,
          DisableClearable
        >) ?? value);
  }, [value, reportOptions]);

  function getPlaceholder(): string {
    if (multiple) {
      if (props.loading) return "";
      return isOpen
        ? t("notifications:typeto")
        : !(selected as ReportOption[]).length
          ? t("notifications:allreports")
          : placeholder;
    }

    return isOpen
      ? t("notifications:typeto")
      : ((selected as ReportOption)?.name ?? placeholder);
  }

  if (props.loading || loadingTemplates) {
    return (
      <Skeleton
        variant="rect"
        width={300}
        height={41}
        style={{ borderRadius: 8 }}
      />
    );
  }

  return (
    <Autocomplete
      {...props}
      className={className}
      disabled={disabled}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={(e, r) => {
        if (isOpen && r === "toggleInput" && e.type === "mousedown") return;
        setIsOpen(false);
        setInputValue("");
      }}
      disableCloseOnSelect={multiple}
      disableClearable={disableClearable || isOpen}
      multiple={multiple}
      value={selected}
      onChange={(_, selection) => {
        if (Array.isArray(selection) && multiple) {
          const newElement = differenceBy(
            selection,
            value as ReportOption[],
            "code",
          );
          if (newElement?.length) setScrollTo(newElement[0].id);
          onChange(selection as Value<Multiple, DisableClearable>);
        } else {
          onChange(selection as Value<Multiple, DisableClearable>);
          setIsOpen(false);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (!event) return;
        if (event.type === "change") setInputValue(newInputValue);
      }}
      classes={{
        ...omit(outsideClasses, "root", "option", "listbox", "paper"),
        root: clsx(
          outsideClasses?.root,
          Array.isArray(value) && value.length ? classes.root : undefined,
        ),
        option: clsx(
          outsideClasses?.option,
          multiple
            ? classes.autocompleteOptionMulti
            : classes.autocompleteOption,
        ),
        listbox: outsideClasses?.listbox,
        paper: clsx(outsideClasses?.paper, classes.paper),
        inputRoot: classes.inputRoot,
      }}
      onBlur={(e) => {
        onBlur?.(e);
        if (inputValue !== "") setInputValue("");
      }}
      options={currentList ?? []}
      getOptionLabel={(option) => option.name}
      renderGroup={(option) => (
        <ReportOptionGroupElement
          key={option.key}
          group={option}
          categories={categories}
        />
      )}
      groupBy={(option) => {
        if (isSelected(option) && multiple) return "";
        return option.category?.parentName?.length
          ? option.category?.parentName
          : "SEO";
      }}
      getOptionDisabled={(option) => {
        const hasReachedMaxNumberOfReports = Array.isArray(value)
          ? value.length === maxSelection
          : false;
        if (multiple && Array.isArray(value)) {
          const isSelected = value.some((v) => v.code === option.code);
          return hasReachedMaxNumberOfReports && !isSelected;
        }
        return false;
      }}
      renderOption={(option, state) => {
        const disabled = hasReachedMaxNumberOfProjects && !state.selected;
        return (
          <ReportOptionElement
            key={option.code}
            option={option}
            state={state}
            disabled={disabled}
            maxSelection={maxSelection}
            group={option.category?.code}
            parentGroup={option.category?.parentName}
            multiSelect={multiple}
          />
        );
      }}
      getOptionSelected={(option, value) => {
        return option.code === value.code;
      }}
      fullWidth
      style={{ maxWidth: maxWidth ?? 300 }}
      openOnFocus
      PopperComponent={LeftBottomPopper}
      renderInput={(params) => {
        const title = getPlaceholder();
        if (variant === "select" && !isOpen)
          return (
            <>
              <TextField {...params} style={{ display: "none" }} />
              <Tooltip
                title={
                  comboboxTextWidth && comboboxTextWidth >= COMBOBOX_WIDTH
                    ? title
                    : ""
                }
                arrow={false}
              >
                <Button
                  {...params}
                  style={{ paddingRight: 11, width: "100%" }}
                  variant="text"
                  color="primary"
                  size="large"
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(true);
                  }}
                  className={classes.selectButton}
                  aria-label={t("common:selectReport", {
                    name: getPlaceholder(),
                  })}
                >
                  <Typography
                    variant="h6SemiBold"
                    id="combobox-button-placeholder"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: 20,
                      fontSize: theme.typography.pxToRem(18),
                      marginTop: 2,
                      color: !selected ? theme.palette.grey[300] : undefined,
                    }}
                  >
                    {getPlaceholder()}
                  </Typography>
                  <div style={{ flex: 1 }} />
                  <ChevronDownSolid style={{ width: 20, height: 20 }} />
                </Button>
              </Tooltip>
            </>
          );
        return (
          <TextField
            {...params}
            variant="outlined"
            onBlur={(e) => onBlur?.(e)}
            InputProps={{
              ...params.InputProps,
              autoFocus: variant === "select",
              className: clsx(classes.input, params.InputProps.className),
            }}
            placeholder={getPlaceholder()}
          />
        );
      }}
      renderTags={(selectedReports) => {
        return !isOpen ? (
          <span>
            {t("notifications:reportsselected", {
              count: selectedReports.length,
            })}
          </span>
        ) : null;
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&:not(.Mui-focused) .MuiAutocomplete-inputRoot": {
      "&:hover fieldset": {
        borderColor: `${theme.palette.ultraviolet[400]} !important`,
      },
      backgroundColor: theme.palette.ultraviolet[100],
      color: theme.palette.grey[700],
      fontWeight: 500,
      "& fieldset": {
        borderColor: theme.palette.ultraviolet[400],
      },
    },
  },
  autocompleteOption: {
    padding: 0,
    "&[aria-disabled='true']": {
      pointerEvents: "all!important",
      cursor: "default",
    },
    "&[aria-selected='true']": {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.ultraviolet[200],
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  autocompleteOptionMulti: {
    padding: 0,
    "&[aria-disabled='true']": {
      pointerEvents: "all!important",
      cursor: "default",
    },
    "&[aria-selected='true']": {
      color: theme.palette.grey[700],
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  input: {
    color: theme.palette.grey[700],
    "& input::placeholder": {
      color: theme.palette.grey[700],
      opacity: 1,
      textOverflow: "ellipsis",
    },
  },
  paper: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  inputRoot: {
    flexWrap: "nowrap",
  },
  selectButton: {
    color: theme.palette.grey[900],
    boxShadow: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
